import React, {ReactNode, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {graphql, PageProps, useStaticQuery} from 'gatsby';

import {Page} from '../types';
import {useStore} from '../store';
import {ThemeColor} from '../theme';

import {DownloadBar, Footer, NavHeader} from '../features/navigation';
import {isBrowser} from '../utils/isBrowser';
import {useScreenSize} from '../utils/useScreenSize';
import {CookieBanner} from '../features/CookieBanner';

const PageWrapper = ({
  element,
  props,
}: {
  element: ReactNode;
  props: PageProps;
}) => {
  const {sideMenuOpen, showCookieConsent, onAcceptCookies, onDeclineCookies} =
    useStore();

  const data = useStaticQuery(graphql`
    query PageWrapper {
      datoCmsShared {
        showPromoBanner
        promoItems {
          text
          link
        }
      }
    }
  `);

  const sharedData = data.datoCmsShared;

  const activePage = React.useMemo((): Page => {
    if (props.location.pathname.match(/^\/articles\/$/)) {
      return 'articles';
    } else if (props.location.pathname.indexOf('/articles/') === 0) {
      return 'article';
    } else if (props.location.pathname.match(/^\/questions\/$/)) {
      return 'questions';
    } else if (props.location.pathname.indexOf('/questions/') === 0) {
      return 'question';
    }
    if (
      props.location.pathname.indexOf('/parents/') === 0 &&
      props.location.pathname !== '/parents/'
    ) {
      return 'parents-article';
    }

    if (
      props.location.pathname.indexOf('/premium/') === 0 &&
      props.location.pathname !== '/premium/'
    ) {
      return 'premium-transaction';
    }
    switch (props.location.pathname) {
      case '/parents/':
        return 'parents';
      case '/teens/':
        return 'teens';
      case '/download/':
        return 'download';
      case '/about-us/':
        return 'about-us';
      case '/work-with-us/':
        return 'work-with-us';
      case '/applink/':
        return 'applink';
      case '/404/':
        return '404';
      case '/privacy-policy/':
        return 'privacy-policy';
      case '/terms-conditions/':
        return 'terms-conditions';
      case '/premium/':
        return 'premium';
      case '/reports/':
        return 'reports';
      case '/shop/':
        return 'shop';
      case '/schools/':
        return 'schools';
      case '/newsletter-sign-up/':
        return 'newsletter-sign-up';
      case '/usa-waitlist/':
        return 'USA-waitlist';
      case '/usa-waitlist-parents/':
        return 'usa-waitlist-parents';
      default:
        return 'home';
    }
  }, [props]);

  const backgroundColour = React.useMemo((): ThemeColor => {
    switch (activePage) {
      case 'parents':
        return 'relationships';
      case 'premium':
        return 'relationships';
      case 'teens':
        return 'sex';
      case 'download':
        return 'sandwisp';
      case 'about-us':
        return 'background';
      case 'work-with-us':
        return 'relationships';
      case 'applink':
        return 'background';
      case 'home':
        return 'creole';
      case 'articles':
        return 'background';
      case 'article':
        return 'white';
      case 'questions':
        return 'background';
      case 'question':
        return 'white';
      case 'reports':
        return 'background';
      case 'shop':
        return 'sandwisp';
      case 'schools':
        return 'bodyimage';
      case 'parents-article':
        return 'white';
      case 'newsletter-sign-up':
        return 'white';
      case 'USA-waitlist':
        return 'sandwisp';
      case 'usa-waitlist-parents':
        return 'sandwisp';
      default:
        return 'background';
    }
  }, [activePage]);

  // prevent scrolling when side menu is open
  // NOTE: we are doing this in JS as Helmet plugin is soon to be deprecated and the Head API
  // is a terrible fit for this as would need to be implemented for EVERY page
  useEffect(() => {
    if (isBrowser) {
      if (sideMenuOpen) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    }
  }, [sideMenuOpen]);

  const screenSize = useScreenSize();

  const downloadCTA = useMemo(() => {
    if (screenSize === 'extra-large') {
      return 'Download the app';
    } else if (screenSize === 'large') {
      return 'Download the app';
    } else {
      return 'Download';
    }
  }, [screenSize]);

  const downloadBarCopy = 'Join the luna community today';

  return (
    <div className="min-h-screen text-black">
      <NavHeader
        activePage={activePage}
        showBanner={sharedData.showPromoBanner}
        bannerItems={sharedData.promoItems}
      />
      <div className="relative">{element}</div>
      <Footer bgColour={backgroundColour} />
      <CookieBanner
        showCookieConsent={showCookieConsent}
        onAcceptCookies={onAcceptCookies}
        onDeclineCookies={onDeclineCookies}
      />
      {!showCookieConsent && (
        <DownloadBar
          downloadCTA={downloadCTA}
          downloadBarCopy={downloadBarCopy}
        />
      )}
    </div>
  );
};

PageWrapper.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PageWrapper;
